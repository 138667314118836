import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomModal from '../components/CustomModal';
import { ReactComponent as Dots } from 'images/symbols/dots_form.svg';
import { ReactComponent as Capsules } from 'images/symbols/capsules.svg';
import { ReactComponent as WaveBottom } from 'images/symbols/wave_red.svg';
import { ReactComponent as Arrow } from 'images/symbols/arrow.svg';

export type TContent = {
  title: string;
  subtitle: string;
  link?: string;
  description: { title?: string; text: string | string[]; link?: string | string[]; email?: string }[];
};
const oTym = 'https://www.otymsiewie.pl';
const facebook = 'https://www.facebook.com/FunduszenorweskieEOG';
const gimpPL = [
  'https://www.youtube.com/playlist?list=PL3OoWtWa9qoAD-649vS4XLWHN8Yx60oBu\n',
  'https://www.youtube.com/c/CzarnyByd/videos'
];

const gimpEN = ['https://www.youtube.com/watch?v=bxrDIAtvQfc\n', 'https://www.youtube.com/watch?v=lOzSiOIipSM'];
const krita = [
  'https://www.youtube.com/watch?v=60EzhNLLhLM\n',
  'https://www.youtube.com/watch?v=Z06RRp81iDM\n',
  'https://www.youtube.com/watch?v=3dxRuXmRChs&list=PLBZfJUa5uZijev1mj1K4AqWqKTEYBo4H3'
];

const projects = [
  'https://www.eog.gov.pl/media/93721/Przewodnik_Kierunek_Polska.pdf\n',
  'https://www.eog.gov.pl/media/70237/Takk_publikacja_MR.pdf'
];

const fundsThirdEdition = [
  'https://www.eog.gov.pl/strony/zapoznaj-sie-z-funduszami/oferta-funduszy/srodowisko-energia-zmiany-klimatu/ '
];

const contactEmail = 'konkurs@otymsiewie.pl';

const boxContent = [
  {
    title: 'Uwaga! Konkurs dla uczniów z multimedialnymi nagrodami!',
    subtitle:
      'Jeśli chcielibyście wygrać pracownię multimedialną dla swojej szkoły, weźcie udział w konkursie na temat Funduszy Norweskich i EOG! Do wygrania aż sto pracowni multimedialnych składających się z kilkunastu laptopów i szafy do ich przechowywania. Sprawdźcie, co zrobić, by wziąć udział w konkursie.',
    description: [
      {
        text: [
          'Czy wiecie, co to są Fundusze Norweskie i EOG? Jeśli jeszcze nie wiecie, najważniejsze informacje na ich temat znajdziecie na stronie internetowej eog.gov.pl i profilu tych Funduszy na Facebook’u',
          'lub na stronie konkursu. Ta wiedza będzie niezbędna, aby wykonać zadanie konkursowe.'
        ],
        link: facebook
      },
      {
        title: 'Co trzeba zrobić, by wziąć udział w konkursie?',
        text: `Należy wykonać pracę plastyczną w dowolnej technice cyfrowej, ale wysłać należy ją w określonych w regulaminie formatach, poprzez formularz zgłoszeniowy zamieszczony na stronie (Weź udział). Tematyka tych prac musi być związana właśnie z Funduszami Norweskimi i EOG – tu musicie wykazać się pomysłowością oraz wiedzą dotyczącą państw-darczyńców.`
      },
      {
        title: 'Kto może wziąć udział w konkursie?',
        text: `Uczniowie szkół podstawowych i ponadpodstawowych, których pracę będzie nadzorował nauczyciel, pedagog, wychowawca. Zgłoszenia pracy do konkursu musi dokonać osoba pełnoletnia. O tym, jak liczne mogą być drużyny, ile prac mogą przygotować i przesłać, dowiecie się z Regulaminu Konkursu zamieszczonego na stronie`,
        link: oTym
      },
      {
        title: 'Do kiedy należy nadesłać prace? ',
        text: 'Czasu jest całkiem sporo, na Wasze zgłoszenia czekamy do 31 marca 2021 r.'
      }
    ]
  },
  {
    title: 'Dlaczego warto wziąć udział w konkursie?',
    subtitle: `O multimedialnych nagrodach, które można wygrać, już wiecie, jednak to nie wszystko! Ideą konkursu organizowanego przez NASK, Ministerstwo Funduszy i Polityki Regionalnej (jako Krajowy Punkt Kontaktowy dla Funduszy Norweskich i EOG), pod patronatem Ambasady Królestwa Norwegii oraz Ministerstwa Edukacji i Nauki, jest zdobywanie i rozwijanie kompetencji cyfrowych, które są tak ważne w dzisiejszym świecie.`,
    description: [
      {
        text: `NASK Państwowy Instytut Badawczy od wielu lat zaangażowany jest w dbanie o bezpieczeństwo dzieci i młodzieży w sieci oraz podnoszenie poziomu kompetencji cyfrowych najmłodszych jej użytkowników. Dostarczamy do szkół szybki, bezpieczny i darmowy internet w ramach projektu Ogólnopolskiej Sieci Edukacyjnej (OSE). Szczególną rolę NASK przywiązuje do bezpieczeństwa dzieci w sieci poprzez realizację programu Safer Internet, prowadząc zespół Dyżurnet.pl oraz edukując dzieci, rodziców i nauczycieli w ramach Akademii NASK.

        Te działania są szczególnie ważne teraz, gdy komputery, internet, szukanie wiedzy w sieci stały się codziennością zarówno uczniów, jak i nauczycieli. Cyfrowa szkoła stała się rzeczywistością. Warto twórczo wykorzystać nowe technologie, uzyskać wiedzę, dodatkowe umiejętności, a sto drużyn przy okazji zdobędzie też dla swojej szkoły pracownie multimedialne.

        Nasz konkurs ma na celu zachęcenie uczniów do podnoszenia wiedzy na tematy związane z geografią, wiedzą o społeczeństwie, ale też zdobywanie nowych umiejętności cyfrowych. Wykonanie pracy konkursowej w technice cyfrowej może dostarczyć nowych, niezapomnianych wrażeń, otworzyć  uczniów i nauczycieli na nowe doświadczenia, ale przede wszystkim przybliżyć możliwości, jakie dają nam komputery, kamery, aparaty fotograficzne, nawet te, które mamy w smartfonach.

        Żeby poszukać inspiracji dla swoich prac, sprawdźcie, co to są Fundusze Norweskie i EOG, co łączy Norwegię, Islandię i Lichtenstein, jakie wartości i działania wspierają Fundusze Norweskie i EOG.

        Zobaczcie, ile dobrej, mądrej zabawy i rozrywki może dostarczyć Wam udział w konkursie!`
      }
    ]
  },
  {
    title: 'Prace graficzne techniką cyfrową',
    subtitle:
      'Udział w konkursie wymaga wykonania pracy plastycznej techniką cyfrową. Jak się za to zabrać? Wspomóc was mogą filmy instruktażowe, publikowane przez znawców tematu. Obejrzyjcie, spróbujcie i bawcie się dobrze!',
    description: [
      {
        text:
          'Kiedy już wiemy, co chcemy przedstawić, trzeba wykorzystać odpowiednie narzędzie, które pozwoli przetworzyć naszą wizję w cyfrowy obraz. Poniżej przedstawiamy przykładowe instrukcje użycia bezpłatnych programów do obróbki grafiki.'
      },
      {
        text: 'Filmy o GIMP po polsku:\n',
        link: gimpPL
      },
      {
        text: 'Filmy o GIMP po angielsku:\n',
        link: gimpEN
      },
      {
        text: 'Filmy o KRITA po angielsku:\n',
        link: krita
      }
    ]
  },
  {
    title: 'Konkurs trwa! Nie czekajcie, weźcie udział! Wygrajcie laptopy dla Waszych szkół! ',
    subtitle:
      '20 stycznia tego roku wystartował konkurs plastyczno-cyfrowy dla uczniów szkół podstawowych i ponadpodstawowych. Dokładne zasady konkursu opisane są w Regulaminie.  Konkursową pracę plastyczną należy wykonać w technice cyfrowej, jednak zgłosić ją do Konkursu można tylko w czerech dozwolonych formatach – jpg, png, mp4 lub gif. Jak to zrobić? Czy projekt strony internetowej lub prezentacja mogą być pracą konkursową? ',
    description: [
      {
        text: `Konkurs „Fundusze Norweskie i EOG – O tym się wie!” cieszy się popularnością, czego dowodem, jest między innymi liczba maili od szkół z pytaniami o zasady uczestnictwa w Konkursie. Wśród nich często pojawia się wątpliwość, czy projekt strony internetowej lub prezentacja w PowerPoint mogą być formą wykonania  pracy konkursowej i jak ją wówczas zgłosić do Konkursu w jednym z czterech dozwolonych formatów. Oczywiście, zarówno projekt strony www, jak i prezentacja w PowerPoint lub innym programie to dobre narzędzia do wykonania cyfrowej pracy plastycznej. Aby ją później zgłosić do Konkursu można zastosować jedno z poniższych rozwiązań:

        1. zapisać każdą stronę projektu witryny internetowej lub prezentacji w formacie jpg lub png  i w tej formie zgłosić do Konkursu,

        2. nagrać film (można użyć w tym celu nawet smartfona) pokazujący funkcjonalność strony lub prezentację i zgłosić do Konkursu jako plik mp4.`
      },
      {
        text: 'Jeśli macie jeszcze jakieś pytania dotyczące przesyłania prac, piszcie na adres: ',
        email: contactEmail
      }
    ]
  },
  {
    title: 'Ekologia, kultura, nauka i nie tylko!',
    subtitle:
      'Kolejne pytania od szkół dotyczą tematyki prac konkursowych. Zawarta w Regulaminie informacja, iż prace konkursowe powinny poruszać tematykę Funduszy Norweskich i EOG nie zawsze wszystkich od razu naprowadza na właściwy trop. Czy trzeba pokazać projekty realizowane w najbliższej okolicy? Czy można wybrać i pokazać dowolny obszar, który jest zasilany środkami z Funduszy Norweskich i EOG? A może zdecydować się na pokazanie gejzerów?',
    description: [
      {
        text:
          'Odpowiadając na pytania dotyczące tematyki prac chcemy przypomnieć, że Fundusze Norweskie i Fundusze Europejskiego Obszaru Gospodarczego (EOG) to pomoc finansowa dla kilkunastu krajów Unii Europejskiej o niższych wskaźnikach rozwoju, którą przekazują Norwegia, Islandia i Liechtenstein. W  zamian za to, państwa te korzystają z dostępu do rynku wewnętrznego UE, mimo że nie są jej członkami.  '
      },
      {
        text:
          'W dwóch dotychczasowych edycjach Funduszy Polska zagospodarowała z pożytkiem ponad 1 miliard euro, realizując ok. 2 700 projektów zmierzających do zmniejszania różnic społeczno-gospodarczych. Ich przykłady znajdziesz m. in. tutaj:\n',
        link: projects
      },
      {
        text: `Aktualnie trwa III edycja Funduszy Norweskich i EOG. Na co mogą być przeznaczone środki? Podstawowe obszary wsparcia to m. in.:

          • budowanie potencjału kadry naukowej i rozwój instytucjonalny podmiotów działających w sferze edukacji;
        
          • kontynuacja wyjątkowo efektywnej polsko-norweskiej współpracy badawczej;
        
          • rozwój miast z najtrudniejszą sytuacją społeczno-gospodarczą.
        
          • ochrona dziedzictwa kulturowego, przedsiębiorczość w obszarze kultury i różnorodność kulturowa;
        
          • wzmocniona współpraca z policją i jeszcze skuteczniejsze zwalczanie przestępczości i poprawę bezpieczeństwa;
        
          • wzmocniona profilaktyka i ograniczanie nierówności społecznych w zdrowiu;
        
          • stymulowanie innowacyjności i konkurencyjności polskich przedsiębiorstw,
        
          • wzmocnienie systemu przeciwdziałania przemocy domowej,
        
          • łagodzenie zmian klimatycznych poprzez np. realizację inwestycji w zielono-niebieską infrastrukturę w miastach. 
          `
      },
      {
        text: 'Z tematyką poszczególnych programów III edycji Funduszy możecie zapoznać się tutaj:\n',
        link: fundsThirdEdition
      },
      {
        text: 'Powyższe zagadnienia mogą być również tematem Waszej pracy konkursowej.'
      },
      {
        text:
          'Zachęcamy też do zrobienia rozeznania, czy w Waszej najbliższej okolicy realizowany był jakiś projekt finansowany z Funduszy Norweskich i EOG. Jeśli tak, możecie pokazać efekty tego projektu. W swoich pracach możecie pokazać też historię powstania Funduszy Norweskich i EOG. A jeśli od wiedzy polityczno-społecznej wolicie geografię i podróże, zabierzcie nas w swoich pracach w podróż do Norwegii, Islandii lub Lichtensteinu. Inspiracji możecie też szukać na profilu Funduszy Norweskich i EOG na Fb:',
        link: facebook
      }
    ]
  },
  {
    title: 'Podróże na komputerze, które mogą przynieść fantastyczne nagrody!',
    subtitle:
      'Wszystkie trzy państwa tworzące EOG są niesłychanie ciekawe – mają unikatową historię, przyrodę, kulturę. Dziś, gdy podróże są nieco utrudnione, warto zwiedzać świat z pomocą komputerów, internetu.  W tym wypadku podróże nie tylko kształcą, ale też mogą przynieść nagrodę. Wystarczy tylko, że wiedzę o Norwegii, Islandii i Lichtensteinie przełożycie na pracę plastyczno-cyfrową i zgłosicie do Konkursu. Żeby rozbudzić w sobie chęć do wirtualnego zwiedzania, przeczytajcie kilka ciekawostek o tych trzech państwach.',
    description: [
      {
        text: `Norwegia zajmuje północną i zachodnią część Półwyspu Skandynawskiego. Jej stolicą jest Oslo, jednocześnie jest to największe miasto w tym kraju, choć i tak daleko mu do zatłoczonego Paryża czy Londynu. A to dlatego, że Norwegia, jest drugim po Islandii najmniej zaludnionym europejskim krajem. Jej powierzchnia jest nieco większa od Polski, a żyje w niej prawie osiem razy mniej mieszkańców, czyli ponad 5 mln osób. Choć  mieszkańców nie ma wielu, na pewno wielu z nich pasjonuje się sportami zimowymi. Norwegia zdobyła bowiem najwięcej złotych, srebrnych oraz brązowych medali ze wszystkich krajów podczas zimowych igrzysk olimpijskich w historii. Kraj słynie ze wspaniałych, śnieżnych gór, tras do uprawiania narciarstwa biegowego, lodowisk i tego, wszystkiego, co miłośnicy zimy i sportu lubią najbardziej. Na północy kraju są miejsca, gdzie śnieg leży przez cały rok. Nic więc dziwnego, że z Norwegią kojarzą się też renifery, które ciągną sanie… czasami ze świętym Mikołajem. Renifery żyją głównie na północy kraju i są tam traktowane jako zwierzęta hodowlane. Ich hodowlą od wieków zajmują się Saamowie (Lapończycy). A skoro sięgamy wieki wstecz, nie sposób nie wspomnieć o wikingach, czyli skandynawskich, a więc również norweskich wojownikach, którzy zasłynęli swoimi morskimi wyprawami do odległych części Europy, a nawet dalej. Uważa się, że dopłynęli do Ameryki Północnej długo przed Kolumbem.

        Islandia to państwo położone na wyspie Islandia i kilku mniejszych wyspach w północnej części Oceanu Atlantyckiego. Stolicą państwa jest Reykjavik. Pod względem geologicznym jest to najmłodszy obszar kontynentu europejskiego. Konsekwencją tego są między innymi liczne czynne wulkany, gorące źródła czy gejzery, pokrywające Islandię. Posiadanie takich gorących źródeł ma swoje plusy – zimą, drogi i chodniki polewane są ciepłą wodą pochodzącą z tych źródeł i nie wymagają mozolnego odśnieżania. W Islandii mieszka nieco ponad 360 tysięcy osób i jak dodają inni – jest tam dwa razy więcej owiec, niż ludzi. To właśnie hodowla tych zwierząt, poza rybołówstwem jest ważną gałęzią gospodarki tego kraju. Islandia ma wspaniałe tradycje, o których niewiele osób wie – język islandzki jest jednym z najstarszych języków na świecie, praktycznie nie zmienił się od tysiąca lat. Zaś islandzki Althing jest najstarszym istniejącym parlamentem na świecie. Po raz pierwszy zebrał się w 930 roku! Wszystkie dzieci na pewno zaciekawi to, że Islandczycy wierzą w 13 różnych Mikołajów! Ciekawe, czy wszyscy przynoszą im prezenty? Wiemy za to na pewno, że ci, którzy nie zachowywali się dobrze, zamiast rózgi od Mikołaja dostają… ziemniaka.

        Lichtenstein to niewielkie alpejskie państwo leżące pomiędzy Szwajcarią a Austrią nad rzeką Ren, ze stolicą w Vaduz. To ciekawe położenie daje Lichtensteinowi miano państwa podwójnie śródlądowego. Oznacza to, że nie tylko sam nie ma dostępu do morza, ale nie posiadają go również graniczące z nim kraje. Na świecie są tylko dwa kraje o takim położeniu – poza Lichtensteinem jest to Uzbekistan. Liechtenstein zamieszkuje niecałe 39 tysięcy obywateli, czyli mniej więcej tyle co średniej wielkości polskie miasto, np. Bolesławiec. Ustrojem Lichtensteinu jest monarchia konstytucyjna. Władza prawodawcza należy do księcia i landtagu (parlamentu), liczącego 25 deputowanych i wybieranego co cztery lata w wyborach proporcjonalnych. Książę ma prawo absolutnego weta w stosunku do ustaw uchwalanych przez parlament. Państwo nie ma swojej armii, bezpieczeństwo zewnętrzne zapewnia mu Szwajcaria. Językiem urzędowym w Liechtensteinie jest język niemiecki, a konkretnie dialekt alemański. Na obszarze tak małego kraju wyróżnia się aż 11 dialektów języka niemieckiego. Mieszkańcy Lichtensteinu bardzo lubią sporty zimowe, mają liczne osiągnięcia na zimowych igrzyskach olimpijskich, jednak równie popularnym sportem w tym kraju jest… piłka nożna. Funkcjonuje tu aż osiem klubów piłkarskich, a najbardziej utytułowanym z nich jest stołeczny FC Vaduz. 

        Mamy nadzieję, że te krótkie „podróże” do trzech państw tworzących Fundusze Norweskie i EOG przyniosły Wam inspiracje niezbędne do stworzenia pracy i wzięcia udziału w konkursie!`
      }
    ]
  }
];

const Footer: React.FC<{}> = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<TContent>();
  const readMore: string = t('inspirations.btn-label');

  const handleClick = (content: TContent) => {
    setContent(content);
    setOpen(true);
  };

  const ContentComponent = () => {
    return (
      <>
        <h1 className="col inspire-modal-title">
          {content?.title}
          <Arrow className="arrow left" />
          <Arrow className="arrow right rotate" />
        </h1>
        <h3 className="col inspire-modal-subtitle">
          {content?.link ? (
            <span>
              {content?.subtitle.slice(0, -1)}
              <a href="/"> {content?.link}</a>.
            </span>
          ) : (
            content?.subtitle
          )}
        </h3>
        <div className="col inspire-modal-description inspire-modal-description-inline">
          {content?.description.map((el, index) => (
            <div key={index}>
              {Array.isArray(el.text) ? (
                <>
                  <p>{el.text[0]}</p>{' '}
                  <a href={el.link as string} target="_blank" rel="noopener noreferrer">
                    {el.link}
                  </a>{' '}
                  <p>{el.text[1]}</p>
                </>
              ) : (
                <>
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>{' '}
                  {Array.isArray(el.link)
                    ? el.link.map((link) => (
                        <a key={link} href={link} target="_blank" rel="noopener noreferrer">
                          {link}
                        </a>
                      ))
                    : el.link && (
                        <span>
                          <a href={el.link} target="_blank" rel="noopener noreferrer">
                            {el.link}
                          </a>
                          .
                        </span>
                      )}
                  {el.email && <a href={`mailto:${el.email}`}>{el.email}</a>}
                </>
              )}
            </div>
          ))}
        </div>
      </>
    );
  };

  const inspireCards = boxContent.map((content) => (
    <Col key={content.title} lg={4} className="p-0">
      <Card.Body>
        <Card.Title as="h3">{content.title}</Card.Title>
        <Card.Text>{content.subtitle}</Card.Text>
        <input onClick={() => handleClick(content)} type="button" className="btn-blue" value={readMore} />
      </Card.Body>
    </Col>
  ));

  return (
    <div className="inspirations-section-wrapper">
      <div className="symbols">
        <Capsules className="capsules" />
        <Dots className="dots" />
        <WaveBottom className="wave" />
      </div>
      <Row lg={4}>
        <h1 className="col-lg-12 mb-4">{t('inspirations.title')}</h1>
        {inspireCards}
      </Row>
      <CustomModal dots content={<ContentComponent />} show={open} onHide={() => setOpen(false)} />
    </div>
  );
};

export default Footer;
