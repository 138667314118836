import React from 'react';
import { useTranslation } from 'react-i18next';
import { isWithinInterval } from 'date-fns';

const FloatingStreamWindow = () => {
  const { t } = useTranslation();
  const currentDate = new Date();
  return (
    <>
      {isWithinInterval(currentDate, { start: new Date(2021, 8, 15), end: new Date(2021, 8, 18) }) && (
        <section className="floating-stream-window">
          <h1>
            {t('floatingStreamWindow.title')}
            <br />
            {t('floatingStreamWindow.titleSecond')}
          </h1>
          <iframe
            title="floating-stream-window"
            frameBorder="0"
            scrolling="no"
            width="460"
            height="256"
            allowFullScreen
            src="https://www.youtube.com/embed/rbwq2q4POPA?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0"
          ></iframe>
        </section>
      )}
    </>
  );
};

export default FloatingStreamWindow;
