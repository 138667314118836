import React, { PropsWithChildren } from 'react';
import { useMedia } from 'react-use';

interface IProps {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
}

export const WindowSize = React.createContext<IProps>({
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false
});

export const WindowSizeProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const mediumBS = useMedia('(min-width: 768px)');
  const xs = useMedia('(max-width: 575.98px)');
  const lg = useMedia('(min-width: 992px)');
  const xl = useMedia('(min-width: 1200px)');
  const md = mediumBS && !lg;
  const sm = !xs && !md && !lg;

  return (
    <WindowSize.Provider
      value={{
        xs,
        sm,
        md,
        lg,
        xl
      }}
    >
      {children}
    </WindowSize.Provider>
  );
};
