import React, { StrictMode } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CookiesProvider } from 'react-cookie';
import { ErrorBoundary } from 'react-error-boundary';
import FoundsSection from './layout/FoundsSection';
import ContactSection from './layout/ContactSection';
import LabSection from './layout/LabSection';
import AddAwardsSection from './layout/AddAwardsSection';
import InspireSection from './layout/InspireSection';
// import FormSection from './layout/FormSection';
import Footer from './layout/Footer';
import Logos from 'components/Logos';
import CtaSection from './components/CtaSection';
import Navigation from './layout/Navigation';
import HeadSection from './layout/HeadSection';
import RulesSection from './layout/RulesSection';
import WinnersSection from './layout/WinnersSection';
import { WindowSizeProvider } from './context/WindowSize';
import { ActiveSection } from './context/ActiveSection';
import CookieModal from './components/CookieModal';
import GAListener from 'components/analytics/GAPXListener';
import ErrorBoundaryFallback from 'components/ErrorFallback';
import FloatingStreamWindow from 'components/FloatingStreamWindow';
import { useSection } from './hooks/useSection';

const GA_COOKIE = 'GA_COOKIE_MFIPR_PUBLIC';

const App: React.FC = () => {
  const { t } = useTranslation();
  const section = useSection();

  return (
    <StrictMode>
      <Router>
        <Route path="/:slug?">
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onReset={() => window.location.reload()}>
            <CookiesProvider>
              <GAListener cookieName={GA_COOKIE}>
                <CookieModal cookieName={GA_COOKIE} />
                <WindowSizeProvider>
                  <ActiveSection.Provider value={section}>
                    <Navigation />
                    <header id="/">
                      <div className="logos-wrapper d-flex justify-content-center py-2">
                        <div className="logos-wrapper-section row align-items-center">
                          <Logos />
                        </div>
                      </div>
                      <CtaSection blue>
                        <HeadSection />
                      </CtaSection>
                    </header>
                    <main>
                      <CtaSection id={t('navigation.awards')}>
                        <LabSection />
                      </CtaSection>
                      <CtaSection id="dodatkowe" autoHeight>
                        <AddAwardsSection />
                      </CtaSection>
                      <CtaSection id={t('navigation.rules')} blue>
                        <RulesSection />
                      </CtaSection>
                      <CtaSection id={t('navigation.inspirations')} autoHeight>
                        <InspireSection />
                      </CtaSection>
                      {/* <CtaSection id={t('navigation.take-part')}>
                        <FormSection />
                      </CtaSection> */}
                      <CtaSection threshold={0.1} id={t('navigation.winners')}>
                        <WinnersSection />
                      </CtaSection>
                      <CtaSection id={t('navigation.founds')}>
                        <FoundsSection />
                      </CtaSection>
                      <CtaSection id={t('navigation.contact')} autoHeight blue>
                        <ContactSection />
                      </CtaSection>
                    </main>
                    <footer>
                      <CtaSection id={t('navigation.contact')} autoHeight>
                        <Footer />
                      </CtaSection>
                    </footer>
                    <FloatingStreamWindow />
                  </ActiveSection.Provider>
                </WindowSizeProvider>
              </GAListener>
            </CookiesProvider>
          </ErrorBoundary>
        </Route>
      </Router>
    </StrictMode>
  );
};

export default App;
