import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FieldErrors } from 'react-hook-form';
import { IFormData } from 'api';
import classnames from 'classnames';
import { ReactComponent as Sucess } from '../images/symbols/success.svg';
import { ReactComponent as Fail } from '../images/symbols/fail.svg';
import { ReactComponent as Facebook } from 'images/icons/facebook.svg';
import { ReactComponent as Dots } from 'images/symbols/dots_form.svg';

type TServerErrors = {
  serverError: never;
};
interface IProps {
  errors?: FieldErrors<IFormData & TServerErrors>;
  show: boolean;
  onHide: () => void;
  isUploading?: boolean;
  content?: JSX.Element;
  dots?: boolean;
}

const CustomModal: FC<IProps> = ({ content, isUploading, errors, dots, ...props }) => {
  const { t } = useTranslation();

  const Loader = (): JSX.Element => {
    return (
      <div className="loader-wrapper">
        <span className="loader"></span>
        <h4>{t('form.modal.loading_title')}</h4>
        <p>{t('form.modal.loading_description')}</p>
      </div>
    );
  };

  const FailComponent = (): JSX.Element => {
    return (
      <>
        <Fail />
        <h2>{t('error.title')}</h2>
        <p>{errors?.serverError?.message}</p>
      </>
    );
  };
  const SucessComponent = (): JSX.Element => {
    return (
      <>
        <Sucess />
        <h2>{t('form.modal.title')}</h2>
        <h3>{t('form.modal.description')}</h3>
        <p>
          {t('form.modal.follow')}
          <a href={t('contactSection.facebookLink')} target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <Facebook />
          </a>
        </p>
      </>
    );
  };

  return (
    <Modal {...props} size="lg" centered className="custom-modal">
      {dots && (
        <div className="modal-symbols d-none d-md-block">
          <Dots className="dots" />
        </div>
      )}
      <Modal.Header closeButton={!isUploading}></Modal.Header>
      <Modal.Body className={classnames({ textLeft: dots })}>
        {isUploading ? <Loader /> : errors?.serverError ? <FailComponent /> : content || <SucessComponent />}
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
