import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Arrows } from 'images/symbols/arrows.svg';
import { ReactComponent as Capsuls } from 'images/symbols/capsules.svg';
import { ReactComponent as Pluses } from 'images/symbols/pluses.svg';
import pc from 'images/pc.png';

const LabSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="lab-section-wrapper container">
      <div className="symbols">
        <Capsuls className="corner-capsuls" />
        <Pluses className="pluses" />
      </div>
      <div className="content">
        <div>
          <h1>{t('labSection.titleWin')}</h1>
          <h2>{t('labSection.titleLab')}</h2>
          <h3>{t('labSection.subTitle')}</h3>
        </div>
        <div className="content-icons">
          <Arrows className="pc-arrows" />
          <img className="pc" src={pc} alt="" />
          <p className="multiplier"> 100x</p>
        </div>
      </div>
    </div>
  );
};

export default LabSection;
