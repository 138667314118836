import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { useBot } from 'hooks/useBot';
import { services } from 'api';

interface IProps {
  cookieName: string;
}

const neverExpire = (accepted: boolean): Date => {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();
  return accepted ? new Date(year, month + 1, day) : new Date(year, month, day + 1);
};

const CookieModal: React.FC<IProps> = ({ cookieName }) => {
  const [cookies, setCookie] = useCookies([cookieName]);
  const { t } = useTranslation();
  const isBot = useBot();

  const onAccept = (): void => {
    setCookie(cookieName, 'accepted', {
      path: '/',
      expires: neverExpire(true)
    });
  };

  const onCancel = (): void => {
    setCookie(cookieName, 'declined', {
      path: '/',
      expires: neverExpire(false)
    });
  };

  if (isBot) return null;

  return (
    <Modal show={!cookies[cookieName]} className="cookie-modal">
      <Modal.Header>{t('cookies.title')}</Modal.Header>
      <Modal.Body>
        <p>{t('cookies.description')}</p>
        <p>
          {t('cookies.more')}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={`${services.baseURL}${services.getTerms}`}
            download={t('fileNames.policy')}
          >
            {t('cookies.more_link')}.
          </a>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="cookie-modal-button-cancel" onClick={onCancel}>
          {t('cookies.cancel')}
        </Button>
        <Button variant="cookie-modal-button-accept" onClick={onAccept}>
          {t('cookies.accept')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CookieModal;
