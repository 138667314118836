import { WindowSize } from 'context/WindowSize';
import React, { FC, useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactComponent as LeftArrow } from 'images/icons/left-arrow.svg';
import { ReactComponent as RightArrow } from 'images/icons/right-arrow.svg';
import classNames from 'classnames';
import { IWinner } from 'api';

interface IProps {
  show: boolean;
  selectedWinnerId: number;
  onHide: () => void;
  data: IWinner[];
}

const LightBox: FC<IProps> = ({ show, onHide, data, selectedWinnerId }) => {
  const { lg } = useContext(WindowSize);
  const [id, setId] = useState(selectedWinnerId);
  const [isLoading, setIsLoading] = useState(true);
  const { full, schoolName, mediaType } = data[id];

  const handleDecrement = () => {
    if (id > 0) setId(id - 1);
    else setId(data.length - 1);
    setIsLoading(true);
  };

  const handleIncrement = () => {
    if (id < data.length - 1) setId(id + 1);
    else setId(0);
    setIsLoading(true);
  };

  const media =
    mediaType === 'mp4' ? (
      <video
        className={classNames('light-box-video', { 'is-loading': isLoading })}
        onLoadedData={() => setIsLoading(false)}
        preload={'metadata'}
        controls={true}
        src={full}
      >
        <track kind="captions" />
      </video>
    ) : (
      <img
        className={classNames('light-box-image', { 'is-loading': isLoading })}
        onLoad={() => setIsLoading(false)}
        src={full}
        alt=""
      />
    );

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="light-box d-flex justify-content-center"
      animation={false}
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="light-box-content d-flex w-100 justify-content-between align-items-center">
          {lg ? (
            <>
              <button onClick={handleDecrement}>
                <LeftArrow />
              </button>
              <div className={classNames('light-box-swiper', { 'is-loading': isLoading })}>
                {media}
                <p
                  className={classNames('light-box-text py-3 mb-0', { 'is-loading': isLoading })}
                  dangerouslySetInnerHTML={{ __html: schoolName }}
                />
              </div>
              <button onClick={handleIncrement}>
                <RightArrow />
              </button>
            </>
          ) : (
            <>
              <div className={classNames('light-box-swiper', { 'is-loading': isLoading })}>
                {media}
                <p
                  className={classNames('light-box-text py-3 mb-0', { 'is-loading': isLoading })}
                  dangerouslySetInnerHTML={{ __html: schoolName }}
                />
              </div>
              <div className="w-100 d-flex justify-content-between">
                <button onClick={handleDecrement}>
                  <LeftArrow />
                </button>
                <button onClick={handleIncrement}>
                  <RightArrow />
                </button>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LightBox;
