import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { scrollConfig } from '../layout/Navigation';
import { scroller } from 'react-scroll';
import { ReactComponent as Ring } from 'images/symbols/ring.svg';
import { ReactComponent as WaveBottom } from 'images/symbols/waveBottom.svg';
import { ReactComponent as WaveTop } from 'images/symbols/waveTop.svg';
import { ReactComponent as Dots } from 'images/symbols/dots.svg';
import { ReactComponent as Capsules } from 'images/symbols/capsules.svg';
import { ReactComponent as Pluses } from 'images/symbols/pluses.svg';
import { ReactComponent as Icon } from 'images/symbols/black_arrow.svg';
import RedRing from 'images/symbols/redRing.svg';
import kids from 'images/kids.png';

const HeadSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="head-section-wrapper">
      <div className="symbols">
        <img className="kids" src={kids} alt="" />
        <img className="redRing" src={RedRing} alt="" />
        <Pluses className="pluses" />
        <Pluses className="pluses_r" />
        <Capsules className="capsules" />
        <Capsules className="capsules_red" />
        <Ring className="ring_l" />
        <Ring className="ring_r" />
        <Dots className="dots" />
        <WaveBottom className="wave_b" />
        <WaveTop className="wave_t" />
      </div>
      <div className="content">
        <h1>{t('takePartSection.title')}</h1>
        <h2>{t('takePartSection.subtitle')}</h2>
        <h3>{t('takePartSection.description')}</h3>
        <div className="btn-background">
          <Button
            onClick={() => scroller.scrollTo(t('navigation.winners'), scrollConfig)}
            variant="head-section-button"
          >
            {t('form.btn')} <Icon className="ml-4" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HeadSection;
