import React, { useState, useEffect, useContext } from 'react';
import { Nav } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { slide as Menu } from 'react-burger-menu';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { WindowSize } from '../context/WindowSize';
import { navLinksPL } from '../components/navigationElements';
import { ActiveSection } from '../context/ActiveSection';
import { ReactComponent as FlagIconUK } from '../images/icons/united-kingdom.svg';

type IProps = {
  [key: string]: string;
};
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
export const scrollConfig = { duration: 150, smooth: true };

const Navigation: React.FC<IProps> = () => {
  const [open, setOpen] = useState(false);
  const { lg } = useContext(WindowSize);
  const { t } = useTranslation();
  const { slug } = useParams<{ [key: string]: string }>();
  const { section } = useContext(ActiveSection);

  useEffect(() => {
    ScrollTrigger.create({
      start: 'top -100',
      end: 'bottom',
      toggleClass: { className: 'anim--scrolled', targets: '.anim' }
    });
    ScrollTrigger.create({
      start: 'top -100',
      end: 'bottom',
      toggleClass: { className: 'bm-burger-button--scrolled', targets: '.bm-burger-button' }
    });
  }, []);

  useEffect(() => {
    slug ? scroller.scrollTo(slug, scrollConfig) : scroller.scrollTo('/', scrollConfig);
    setOpen(false);
  }, [slug]);

  const toogle = () => setOpen(!open);
  const menuItems = navLinksPL.map(({ name, url }) => (
    <Link key={name} to={url} className={classnames({ active: url === section })}>
      {name}
    </Link>
  ));

  const GlobalMenu = () => {
    return (
      <>
        {menuItems}
        <div className="language">
          <a
            href="https://eeagrants.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>
              <FlagIconUK /> {t('navigation.language')}
            </p>
          </a>
        </div>
      </>
    );
  };

  return (
    <nav>
      {lg ? (
        <Nav className="navigation anim">
          <div className="navigation-links">
            <GlobalMenu />
          </div>
        </Nav>
      ) : (
        <Menu width={'100%'} onOpen={toogle} onClose={toogle} isOpen={open} right>
          <GlobalMenu />
        </Menu>
      )}
    </nav>
  );
};

export default Navigation;
