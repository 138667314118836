import React, { PropsWithChildren, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import TagManager from 'react-gtm-module';

interface IProps {
  cookieName: string;
}

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_TAG_MANAGER_KEY!
};

const GAListener: React.FC<PropsWithChildren<IProps>> = ({ cookieName, children }) => {
  const [cookies] = useCookies([cookieName]);
  useEffect(() => {
    if (cookies[cookieName] && cookies[cookieName] === 'accepted') {
      TagManager.initialize(tagManagerArgs);
    }
  }, [cookies, cookieName]);

  return <>{children}</>;
};

export default GAListener;
