import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import InView from 'react-intersection-observer';
import { ActiveSection } from '../context/ActiveSection';

export interface IProps {
  blue?: boolean;
  threshold?: number;
  autoHeight?: boolean;
  id?: string;
}

const CtaSection: React.FC<PropsWithChildren<IProps>> = ({ children, id, threshold, blue, autoHeight }) => {
  const ref: React.RefObject<HTMLElement & InView> = React.createRef();
  const { setCurrentUrl } = React.useContext(ActiveSection);

  return (
    <InView
      threshold={threshold ?? 0.54}
      ref={ref}
      as="section"
      onChange={(inView, entry) => inView && setCurrentUrl(entry.target.id)}
      id={id}
      className={classnames('ctaSection-wrapper', { blueBG: blue, autoMinHeight: autoHeight })}
    >
      {children}
    </InView>
  );
};

export default CtaSection;
