import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { ReactComponent as RedRing } from 'images/symbols/redRing.svg';
import { ReactComponent as WaveForm } from 'images/symbols/wave_form.svg';
import { ReactComponent as Ring } from 'images/symbols/ring.svg';
import { ReactComponent as Pluses } from 'images/symbols/pluses.svg';
import { Card, Col, Row } from 'react-bootstrap';
import LightBox from 'components/LightBox';
import winnersList from 'data/winnersList.json';
import classNames from 'classnames';
import { IWinner } from 'api';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { services } from 'api';

const SkeletonImage: React.FC<{ src: string }> = ({ src }) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <Card.Img
      className={classNames({ 'skeleton-image': isLoading })}
      onLoad={() => setIsLoading(false)}
      src={src}
      alt=""
    />
  );
};

const WinnersSection: React.FC = () => {
  const winners: Record<string, IWinner[]> = winnersList;
  const categories = Object.keys(winners);
  const [lightBoxParams, setLightBoxParams] = useState({ isOpen: false, id: 0 });
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const { t } = useTranslation();

  const handleSelectCategory = (key: string) => {
    ScrollTrigger.refresh(true);
    setSelectedCategory(key);
  };

  return (
    <div className="winners-section-wrapper winners-short-info">
      <div className="symbols">
        <WaveForm className="wave_form" />
        <RedRing className="redRing" />
        <Ring className="ring" />
        <Pluses className="pluses" />
      </div>
      <h1 className="text-center">{t('winners.title')}</h1>
      <Row>
        {categories.map((key) => (
          <Col key={key} xs="auto" className="my-2">
            <button
              onClick={() => handleSelectCategory(key)}
              className={classNames('winners-category-btn', { selected: selectedCategory === key })}
            >
              {t(`winners.category.${key}`)}
            </button>
          </Col>
        ))}
      </Row>
      <Row>
        {winners[selectedCategory].map((winner, id) => (
          <Col key={winner.id} md="6" lg="4" className="mt-4">
            <Card
              onClick={() => setLightBoxParams({ isOpen: true, id })}
              as="button"
              className="winners-card p-0 w-100 h-100"
            >
              <div className="winners-card-image-wrapper w-100">
                <span className="winners-card-media-label px-2 py-1">{t(`winners.label.${winner.mediaType}`)}</span>
                <SkeletonImage src={winner.thumbnail} />
              </div>
              <Card.Body className="w-100">
                <Card.Text dangerouslySetInnerHTML={{ __html: winner.schoolName }} />
              </Card.Body>
            </Card>
          </Col>
        ))}
        <Col xs="12" className="mt-5 text-center">
          <button
            onClick={() => saveAs(`${services.baseURL}${services.getWinners}`, 'Nagrodzone_prace_uzupelnienie.pdf')}
            className="winners-category-btn"
          >
            Pobierz listę nagrodzonych drużyn
          </button>
        </Col>
      </Row>
      {lightBoxParams.isOpen && (
        <LightBox
          data={winners[selectedCategory]}
          show={lightBoxParams.isOpen}
          onHide={() => setLightBoxParams({ isOpen: false, id: 0 })}
          selectedWinnerId={lightBoxParams.id}
        />
      )}
    </div>
  );
};

export default WinnersSection;
