import React from 'react';
import classnames from 'classnames';
import logoNorway from 'images/icons/logoNorway.png';
import logoNask from 'images/icons/logoNask.png';
import ambasadaLogo from 'images/icons/AmbasadaNorwegii.png';
import edukacjiLogo from 'images/icons/MinisterEdukacji.png';
import logoMripr from 'images/icons/MRiPR_logo.png';

const logos = [
  { src: logoNorway, url: 'https://www.eog.gov.pl/' },
  { src: logoNask, url: 'https://www.nask.pl/' },
  { src: logoMripr, url: 'https://www.gov.pl/web/fundusze-regiony' },
  { src: edukacjiLogo, url: 'https://www.gov.pl/web/edukacja-i-nauka' },
  { src: ambasadaLogo, url: 'https://www.norway.no/pl/poland/' }
];

export interface IProps {
  inlineStyle?: boolean;
}

const Logos: React.FC<IProps> = ({ inlineStyle }) => {
  return (
    <>
      {logos.map(({ src, url }) =>
        url ? (
          <a
            key={src}
            className={classnames({ 'col-12 col-md-6 col-lg-4 col-xl my-4': inlineStyle })}
            rel="noopener noreferrer"
            target="_blank"
            href={url}
          >
            <img src={src} alt="" />
          </a>
        ) : (
          <div key={src} className={classnames({ 'col-12 col-md-6 col-lg-4 col-xl my-4': inlineStyle })}>
            <img src={src} alt="" />
          </div>
        )
      )}
    </>
  );
};

export default Logos;
