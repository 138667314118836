import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import fail from '../images/symbols/fail.svg';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

const ErrorBoundaryFallback: React.ComponentType<FallbackProps> = ({ resetErrorBoundary }) => {
  const { t } = useTranslation();

  return (
    <div className="content-wrapper">
      <div className="error-fallback">
        <img className="error-fallback-img" src={fail} alt="" />
        <h1 className="error-fallback-title">{t('error.fallback-title')}</h1>
        <p className="error-fallback-info">{t('error.fallback-info')}</p>
        <Button onClick={resetErrorBoundary}>{t('error.fallback-button')}</Button>
      </div>
    </div>
  );
};

export default ErrorBoundaryFallback;
