import React from 'react';
import { urlContextProps } from '../context/ActiveSection';

export const useSection = (): urlContextProps => {
  const [section, setSection] = React.useState('/');

  const setCurrentUrl = React.useCallback((currentUrl: string): void => {
    setSection(currentUrl);
  }, []);

  return {
    section,
    setCurrentUrl
  };
};
