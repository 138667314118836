export const navLinksPL = [
  {
    name: 'Start',
    url: '/'
  },
  {
    name: 'Nagrody',
    url: 'nagrody'
  },
  {
    name: 'Zasady i regulamin',
    url: 'zasady'
  },
  {
    name: 'Inspiracje',
    url: 'inspiracje'
  },
  // {
  //   name: 'Weź udział',
  //   url: 'udzial'
  // },
  {
    name: 'Zwycięzcy',
    url: 'zwyciezcy'
  },
  {
    name: 'O Funduszach',
    url: 'fundusze'
  },
  {
    name: 'Kontakt',
    url: 'kontakt'
  }
];
