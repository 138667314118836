import React from 'react';
import { useTranslation } from 'react-i18next';
import slashes from 'images/symbols/contact_slashes.svg';
import ring from 'images/symbols/redRing.svg';
import dots from 'images/symbols/contact_dots.svg';
import { ReactComponent as Facebook } from 'images/icons/facebook_white.svg';

const ContactSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="contact-section-wrapper">
      <div className="symbols">
        <img className="corner-slashes" src={slashes} alt="" />
        <img className="red-ring" src={ring} alt="" />
        <img className="corner-dots" src={dots} alt="" />
      </div>
      <div className="content">
        <h1>{t('contactSection.title')}</h1>
        <div className="row justify-content-center">
          <h3 className="contact-email mb-1">{t('contactSection.email')}</h3>
          <a className="contact-email-link" href={`mailto:${t('contactSection.descirption')}`} aria-label="Email">
            <h3>{t('contactSection.descirption')}</h3>
          </a>
        </div>
        <a href={t('contactSection.facebookLink')} target="_blank" rel="noopener noreferrer" aria-label="Facebook">
          <Facebook />
        </a>{' '}
      </div>
    </div>
  );
};

export default ContactSection;
