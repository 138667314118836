import React from 'react';

export interface urlContextProps {
  section: string;
  setCurrentUrl: (currentUrl: string) => void;
}

export const ActiveSection = React.createContext<urlContextProps>({
  section: '/',
  setCurrentUrl: () => {}
});
