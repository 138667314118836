import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Arrow } from 'images/symbols/arrow.svg';

const LabSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="addawards-section-wrapper container">
      <div className="content text-center">
        <div className="row align-items-center">
          <Arrow className="pc-arrows col" />
          <h1 className="col-auto col-sm-9 text-center">{t('addawards.title')}</h1>
          <Arrow className="pc-arrows col rotate" />
        </div>
        <h2>{t('addawards.subtitle')}</h2>
      </div>
    </div>
  );
};

export default LabSection;
