import React from 'react';
import { useTranslation } from 'react-i18next';
import { services } from 'api';
import Logos from '../components/Logos';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="footer-section-wrapper">
        <div className="row justify-content-center">
          <p className="col-auto">{t('footer.description')}</p>
          <div className="row text-center line align-items-center">
            <Logos inlineStyle />
          </div>
        </div>
      </div>
      <div className="footer-section-policy">
        <a href={`${services.baseURL}${services.getTerms}`} download={t('fileNames.policy')}>
          {t('footer.policy')}
        </a>
      </div>
    </>
  );
};

export default Footer;
