import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { services } from 'api';
import { ReactComponent as Ring } from 'images/symbols/ring.svg';

import { ReactComponent as Dots } from 'images/symbols/dots.svg';
import { ReactComponent as Circle1 } from 'images/symbols/circle_1.svg';
import { ReactComponent as Circle2 } from 'images/symbols/circle_2.svg';
import { ReactComponent as Circle3 } from 'images/symbols/circle_3.svg';
import { ReactComponent as Mark } from 'images/symbols/mark.svg';
import { ReactComponent as Icon } from 'images/symbols/download.svg';
import { ReactComponent as RedRing } from 'images/symbols/redRing.svg';

const HeadSection: React.FC = () => {
  const { t } = useTranslation();

  const SaveFile = () => {
    saveAs(`${services.baseURL}${services.getPolicy}`, `${t('fileNames.terms')}.pdf`);
  };

  const SaveFile2 = () => {
    saveAs(`${services.baseURL}${services.getPolicyUpdate}`, `${t('fileNames.policy2')}.pdf`);
  };

  const SaveFile3 = () => {
    saveAs(`${services.baseURL}${services.getPolicyUpdate2}`, `${t('fileNames.policy3')}.pdf`);
  };

  const SaveFile4 = () => {
    saveAs(`${services.baseURL}${services.getPolicyUpdate3}`, `${t('fileNames.policy4')}.pdf`);
  };

  return (
    <div className="rules-section-wrapper">
      <div className="symbols">
        <Ring className="ring" />
        <RedRing className="redRing" />
        <Dots className="dots" />
      </div>
      <div className="content">
        <h1>
          {t('rulesSection.title')}
          <Mark className="titleMark" />
        </h1>
        <div className="row steps">
          <div className="row col-lg-4 col-12">
            <div className="bg rule_1 col-12 col-sm-6 col-lg-12 col-6 "></div>
            <div className="row step col-12 col-sm-6 col-lg-12 col-6">
              <div className="circle col-auto">
                <Circle1 />
              </div>
              <div className="text col">{t('rulesSection.rule_1')}</div>
            </div>
          </div>
          <div className="row mx-lg-4 col-lg-4 col-12">
            <div className="bg rule_2 col-12 col-sm-6 col-lg-12 col-6 "></div>
            <div className="row step col-12 col-sm-6 col-lg-12 col-6">
              <div className="circle col-auto">
                <Circle2 />
              </div>
              <div className="text col">{t('rulesSection.rule_2')}</div>
            </div>
          </div>
          <div className="row col-lg-4 col-12">
            <div className="bg rule_3 col-12 col-sm-6 col-lg-12 col-6 "></div>
            <div className="row step col-12 col-sm-6 col-lg-12 col-6">
              <div className="circle col-auto">
                <Circle3 />
              </div>
              <div className="text col">{t('rulesSection.rule_3')}</div>
            </div>
          </div>
        </div>
        <h3>{t('rulesSection.description')}</h3>
        <div className="btn-background mb-4">
          <Button onClick={SaveFile}>
            <Icon className="mr-4" /> {t('rulesSection.download')}
          </Button>
        </div>
        <div className="btn-background ml-4 mb-4">
          <Button onClick={SaveFile2}>
            <Icon className="mr-4" /> {t('rulesSection.downloadChanges')}
          </Button>
        </div>
        <div className="btn-background ml-4 mb-4">
          <Button onClick={SaveFile3}>
            <Icon className="mr-4" /> {t('rulesSection.downloadChanges2')}
          </Button>
        </div>
        <div className="btn-background ml-4 mb-4">
          <Button onClick={SaveFile4}>
            <Icon className="mr-4" /> {t('rulesSection.downloadChanges3')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HeadSection;
