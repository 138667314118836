import axios from 'axios';
import { QueryResult, useQuery } from 'react-query';
import errorHandle, { IServerErrorObject } from '../api/errorHandle';

export interface IWinner {
  schoolName: string;
  thumbnail: string;
  full: string;
  mediaType: string;
  id: number;
}

interface IConsentData {
  id: number;
  key: string;
  name: string;
  value: string;
}
type IRequestType = 'policy-consent' | 'copyright' | 'permissions';

type TFileError = {
  message: string;
};

export const services = {
  baseURL: '/api/v1/form',
  getConsent: '/consent?key=',
  getPolicy: '/regulation?key=policy', //Regulamin
  getPolicyUpdate: '/regulation?key=policyupdate', //Regulamin
  getPolicyUpdate2: '/regulation?key=policyupdate2', //Regulamin 2
  getPolicyUpdate3: '/regulation?key=policyupdate3', //Regulamin 3
  getWinners: '/regulation?key=winners',
  getTerms: '/regulation?key=terms' // Polityka prywatności
};

export interface IFormData {
  name: string;
  surname: string;
  email: string;
  team: string;
  school: string;
  phone: string;
  nip: string;
  file: File[] & TFileError;
  reCaptcha: string;
  acceptCopyright: boolean;
  acceptPolicy: boolean;
  acceptPermissions: boolean;
}

export const prepareForm = async (data: IFormData) => {
  const jsonse = JSON.stringify(data);
  const blob = new Blob([jsonse], { type: 'application/json' });
  const formData = new FormData();
  formData.append('form', blob);
  formData.append('file', data.file[0]);

  try {
    const payload = await axios.post<number>(services.baseURL, formData);
    return payload.status;
  } catch (reason) {
    throw errorHandle(reason);
  }
};

const getConsent = async (key: IRequestType): Promise<IConsentData> => {
  try {
    const payload = await axios.get<IConsentData>(`${services.baseURL}${services.getConsent}${key}`);
    return payload.data;
  } catch (reason) {
    throw errorHandle(reason);
  }
};

export const useConsent = (key: string): QueryResult<IConsentData, IServerErrorObject> => {
  return useQuery<IConsentData, IServerErrorObject>(
    key,
    (key: IRequestType): Promise<IConsentData> => getConsent(key),
    {
      retry: 2,
      refetchInterval: false,
      refetchOnWindowFocus: false
    }
  );
};
