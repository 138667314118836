import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WaveForm } from 'images/symbols/wave_form.svg';
import { ReactComponent as Capsules } from 'images/symbols/capsules.svg';
import { ReactComponent as Pluses } from 'images/symbols/pluses.svg';

const eogFounding = ['Ochrona środowiska', 'Dziedzictwo kulturowe', 'Zdrowie', 'Badania naukowe', 'Stypendia'];

const FoundsSection: React.FC = () => {
  const { t } = useTranslation();

  const foundsRange = eogFounding.map((elem) => <li key={elem}>{elem}</li>);

  return (
    <div className="row founds-section-wrapper d-flex justify-content-center">
      <div className="symbols">
        <Pluses className="pluses" />
        <Capsules className="capsules" />
        <WaveForm className="wave" />
      </div>
      <div className="content">
        <h1>{t('foundsSection.title')}</h1>
        <div className="content-text">
          <p>{t('foundsSection.description')}</p>
          <dl>{foundsRange}</dl>
          <p>
            {t('foundsSection.info')}
            <a target="_blank" rel="noopener noreferrer" href="https://www.eog.gov.pl/">
              {t('foundsSection.urlEOG')}
            </a>
            i
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/pg/FunduszenorweskieEOG/posts/">
              {t('foundsSection.urlFB')}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FoundsSection;
